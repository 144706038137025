<script>
import moment from 'moment';
moment.locale('pt-BR');

import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import UserService from '@/services/user'

export default {
  page: {
    title: "Clientes",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Clientes",
      users: [],
      modalError: '',
      fields: [
        { key: 'name', sortable: true, label: 'Nome' },
        { key: 'email', sortable: true, label: 'E-mail' },
        { key: 'cpf', sortable: true, label: 'CPF' },
        { key: 'phoneNumber', sortable: true, label: 'Telefone' },
        { key: 'lastLogin', sortable: true, label: 'Último Acesso', formatter: (value) => {
          if (value) {
            return moment(value).format('DD/MM/YYYY');
          }

          return '';
        } },
        { key: 'createdAt', sortable: true, label: 'Data Cadastro', formatter: (value) => {
            return moment(value).format('DD/MM/YYYY');
        } },
        { key: 'actions', label: 'Ações' },
      ],

      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 20,
    };
  },
  computed: {
    rows() {
      return this.users.length;
    },
    startIndex() {
      return (this.currentPage * this.perPage) - this.perPage + 1;
    },
    endIndex() {
      const endIndex = (this.currentPage * this.perPage);
      if (this.users.length < endIndex) {
        return this.users.length;
      }

      return endIndex;
    }
  },
  methods: {
    search() {
      this.load(this.searchValue);
    },
    load(term = '') {
      let loader = this.$loading.show();

      UserService.getUserList(term).then(res => {
        this.users = res.data;

        loader.hide();
      }, () => {
        loader.hide();
        this.$toast.error('Não foi possível efetuar a consulta');
      })
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"> 
            <div class="row mb-2">
              <div class="col-sm-12 col-lg-6">
                <div class="position-relative search-input search-box">
                  <form @submit.prevent="search" class="align-items-center">
                    <input type="text" class="form-control" v-model="searchValue" placeholder="Pesquisar por nome..." />

                    <button type="submit" class="btn btn-rounded btn-outline-primary ml-2">
                      Pesquisar
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="users"
                :fields="fields"
                responsive="sm"
                head-variant="light"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>

                    <router-link :to="'/sales?user=' + row.item._id">
                      <b-dropdown-item href="javascript: void(0);" @click="editBrand(row.item)">
                        <i class="fas fa-shopping-cart text-warning mr-2 mt-2"></i>
                        Pedidos
                      </b-dropdown-item>
                    </router-link>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="row justify-content-md-between align-items-md-center mt-4">
          <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{rows}}</div>

          <div class="col-xl-5">
            <div class="text-md-right float-xl-right mt-2 pagination-rounded">
              <b-pagination
                v-model="currentPage" 
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style lang="scss">
  .search-box form {
    display: flex;
    flex-direction: row;
  }

  .search-box .form-control {
    padding-left: 20px;
  }

  .input-search {
    width: 90%;
  }
</style>